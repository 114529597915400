<template>
    <footer class="lds-footer">
        <div class="lds-container lds-container_full">
            <div class="lds-footer__content">
                <div class="lds-footer__copyright">
                    Copyright &copy;
                    <a href="http://leads.su/"
                       target="_blank"
                       rel="noopener">
                        LEADS.SU
                    </a>
                    <span class="lds-footer__easter-egg-text"
                          v-if="!!tour1egg7 && tour1egg7.needShow"
                          @click="collect(tour1egg7)">
                        2016-{{ currentYear }}.
                    </span>
                    <span v-else>2016-{{ currentYear }}.</span>
                    <br>
                    Все права защищены.
                </div>
                <div class="lds-footer__social">
                    <div class="lds-social">
                        <a href="https://www.youtube.com/channel/UCPVtMg-ba07yycR5TEw_l7g"
                           class="lds-social__link"
                           target="_blank"
                           rel="noopener"
                           title="Youtube">
                            <span class="lds-social__icon">
                                <SvgIcon name="social-youtube" />
                            </span>
                        </a>
                        <a href="https://vk.com/leads_su"
                           class="lds-social__link"
                           target="_blank"
                           rel="noopener"
                           title="VKontakte">
                            <span class="lds-social__icon">
                                <SvgIcon name="social-vk" />
                            </span>
                        </a>
                        <a href="https://tele.click/leadssunews"
                           class="lds-social__link"
                           target="_blank"
                           rel="noopener"
                           title="Telegram">
                            <span class="lds-social__icon">
                                <SvgIcon name="social-tg" />
                            </span>
                        </a>
                    </div>
                </div>
                <div class="lds-footer__contacts">
                    <a href="tel:+78005008344">+7 (800) 500-8344</a>
                    <a href="mailto:support@leads.su"
                       class="lds-link">support@leads.su</a>
                </div>
                <div
                    v-if="tour3egg4 && tour3egg4.needShow"
                    class="lds-footer__tour3-egg4 lds-footer__tour3-egg4--not-collected"
                    @click="collect(tour3egg4); tour3egg4collected = true;" />
                <div
                    v-else-if="tour3egg4collected"
                    class="lds-footer__tour3-egg4 lds-footer__tour3-egg4--collected" />
            </div>
        </div>
    </footer>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import SvgIcon from '@/components/common/SvgIcon';
import {tour1egg7, tour3egg4} from '@/models/EasterEggs/EasterEgg';

export default {
    name: 'Footer',
    components: {
        SvgIcon,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            tour3egg4collected: false,
        };
    },
    computed: {
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        tour1egg7() {
            return this.getAvailable(tour1egg7);
        },
        tour3egg4() {
            return this.getAvailable(tour3egg4);
        },
    },
    created() {
        this.check(tour1egg7);
        this.check(tour3egg4);
    },
    methods: {
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
    },
};
</script>
